import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import RenderConfigFE from '../../../src/components/render-config-fe';
import LazyLoad from 'react-lazyload';
import ReviewSlider from '../../../src/views/review-slider-common';
import ContactCard from '../../../src/views/contact-card';
import { getConfigFrontEnd } from '../../../src/components/use-config-fe';
import { IAirtableAttachment } from 'src/_model/airtable.model';


const IndexPage = () => {
    const BackgroundImage = getConfigFrontEnd({ name: 'BackgroundImage' });
    const BackdropImage_2 = getConfigFrontEnd({ name: 'BackdropImage_2' });
    const style = {
        background: `url(${BackgroundImage.url})`,
        borderTop: '2px solid #DC782D',
        borderBottom: '2px solid #DC782D'
    };
    const style_2 = {
        height: '335px',
        background: `url(${BackdropImage_2.url})`,
        borderTop: '2px solid #DC782D',
        borderBottom: '2px solid #DC782D'
    };
    const configMetadataTitle = getConfigFrontEnd({ name: 'metadataTitle' });
    const configMetadataDescription = getConfigFrontEnd({ name: 'metadataDescription' });
    const configMetadataImage = getConfigFrontEnd({ name: 'metadataImage' }) as IAirtableAttachment;
    
    return (
        <Layout>
            <SEO title={configMetadataTitle} description={configMetadataDescription} image={configMetadataImage.url} />

            <section className="section section-content custom" style={style}>
                <ContactCard
                    formStep1={{
                        ContactTitle: 'ContactTitle1',
                        ContactDescription: 'ContactDescription1'
                    }}
                    formStep2={{
                        ContactTitle: 'ContactTitle2',
                        ContactDescription: 'ContactDescription2'
                    }}
                    policy="Policy"
                />
            </section>

            <LazyLoad height={400} offset={50}>
                <section className="section section-review">
                    <div className="review container-fluid">
                        <ReviewSlider />
                    </div>
                </section>
            </LazyLoad>

            <LazyLoad height={400} offset={100}>
                <section className="section section-article custom">
                    <div className="container-fluid" style={{ maxWidth: '1400px', textAlign: 'center' }}>
                        <RenderConfigFE className="title" name="FooterTitle" type="html" />
                        <RenderConfigFE className="footer-content" name="FooterContent2" type="html" />
                    </div>
                </section>
            </LazyLoad>

            <section className="section section-content custom" style={style_2}></section>
        </Layout>
    );
};

export default IndexPage;
